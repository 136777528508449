html,
body,
.App,
#root {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  height: 100%;
}

.ant-table-body {
  min-height: 400px !important;
}
