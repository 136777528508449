/* Unique Container */
.invoice-price-drawer img {
  max-width: 120px;
  margin-bottom: 10px;
}

.invoice-price-drawer.printable-container {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  padding: 30px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Divider */
.invoice-price-drawer .divider {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ddd;
}

/* Invoice Items Table */
.invoice-price-drawer__invoice-items-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-price-drawer__invoice-items-table th,
.invoice-price-drawer__invoice-items-table td {
  padding: 12px;
  text-align: left;
  font-size: 14px;
}

.invoice-price-drawer__invoice-items-table th {
  background-color: #f5f5f5;
  border-bottom: 2px solid #ddd;
  font-weight: 600;
}

.invoice-price-drawer__invoice-items-table td {
  border-bottom: 1px solid #eee;
}

.invoice-price-drawer__invoice-items-table tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

/* Totals Table */
.invoice-price-drawer__totals-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-price-drawer__totals-table td {
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
}

.invoice-price-drawer__totals-table tr:last-child td {
  font-weight: 700;
  background-color: #f0f0f0;
}

/* Terms and Conditions Section */
.invoice-price-drawer__terms-and-conditions {
  font-size: 12px;
  line-height: 1.6;
  color: #444;
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-left: 4px solid #1890ff;
  border-radius: 4px;
}

.invoice-price-drawer__terms-and-conditions ul {
  list-style-type: disc;
  margin-left: 20px;
}

.invoice-price-drawer__terms-and-conditions li {
  margin-bottom: 8px;
}

/* Print-Specific Styles */
@media print {
  body * {
    visibility: hidden;
  }

  @page {
    margin: 20px;
    size: A4;
  }

  .invoice-price-drawer.printable-container,
  .invoice-price-drawer.printable-container * {
    visibility: visible !important;
  }

  .invoice-price-drawer.printable-container {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 30px;
    box-sizing: border-box;
  }

  /* Hide interactive elements */
  .ant-btn {
    display: none;
  }

  /* Adjust table cell sizes for print */
  .invoice-price-drawer__invoice-items-table th,
  .invoice-price-drawer__invoice-items-table td,
  .invoice-price-drawer__totals-table td {
    font-size: 12px;
    padding: 8px;
  }

  .invoice-price-drawer__terms-and-conditions {
    font-size: 10px;
    padding: 10px;
  }

  /* Allow proper page breaks */
  .page-break {
    display: block;
    page-break-before: always;
    break-before: page;
  }
}

@media print {
  .invoice-price-drawer.printable-container {
    height: auto !important;
    overflow: visible !important;
  }
}